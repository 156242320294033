<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-0 ma-0">
              <v-img
                position="left center"
                height="100vh"
                :src="require(`@/assets/images/pages/background.jpg`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card class="auth-card pa-5">
                <!-- logo -->
                <v-card-title class="d-flex align-center justify-center py-7">
                  <v-row class="w-full">
                    <v-col cols="7">
                      <router-link
                        to="/"
                        class="d-flex align-center"
                      >
                        <v-img
                          :src="require('@/assets/images/logos/logo.png')"
                          max-height="42px"
                          alt="logo"
                          contain
                          class="me-3 "
                        ></v-img>
                      </router-link>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    {{ $t('general.forgotPassword') }}?
                  </p>
                  <p class="mb-2">
                    {{ $t('forgotPassword.enterEmail') }}
                  </p>
                </v-card-text>

                <!-- form -->
                <v-card-text>
                  <v-alert
                    v-if="isEmailSent"
                    type="success"
                  >
                    {{ $t('forgotPassword.emailSent') }}
                  </v-alert>
                  <v-form v-if="!isEmailSent">
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="mail@example.com"
                      hide-details
                      class="mb-4"
                    ></v-text-field>

                    <v-btn
                      block
                      color="primary"
                      @click.prevent="sendResetPasswordLink"
                    >
                      {{ $t('forgotPassword.sendLink') }}
                    </v-btn>
                  </v-form>
                </v-card-text>

                <v-card-actions class="d-flex justify-center align-center">
                  <router-link
                    :to="{name:'auth-login'}"
                    class="d-flex align-center text-sm"
                  >
                    <v-icon
                      size="24"
                      color="primary"
                    >
                      {{ icons.mdiChevronLeft }}
                    </v-icon>
                    <span>{{ $t('forgotPassword.backToLogin') }}</span>
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
import { ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import axiosIns from '@axios'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const isEmailSent = ref(false)
    const showErrorBlock = ref(false)

    const sendResetPasswordLink = () => {
      axiosIns
        .post('/forgot-password', {
          email: email.value,
        }).then(() => {
          isEmailSent.value = true
        })
        .catch(() => {
          showErrorBlock.value = true
        })
    }

    return {
      isPasswordVisible,
      showErrorBlock,
      isEmailSent,
      email,
      sendResetPasswordLink,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
